<template>
    <Slide class="coupons-list-content" :step="725" v-if="list.length > 0">
        <template v-slot:leftIcon>
            <div class="left-icon">
                <div style="width: 18px;">
                    <ArrowLeft />
                </div>
            </div>
        </template>
        <template v-slot:rightIcon>
            <div class="right-icon">
                <div style="width: 18px;">
                    <ArrowRight />
                </div>
            </div>
        </template>
        <CouponsItem v-for="(item, index) in list" :key="index" :item="item" />
        <!-- <div class="item" v-for="(item, index) in list" :key="index">
            <img :src="getSignUrl(item.use_status)" class="tui-coupon-sign" v-if="item.use_status >= 1">
        </img>
            <div class="left">
                <div>
                    <span>৳</span>
                    <span>{{ item.value }}</span>
                </div>
                <div>Minimum consumption ৳{{ item.limit }}</div>
            </div>
            <div class="right">
                <div>
                    <div>Coupon</div>
                    <div>All products are available</div>
                </div>
                <div>
                    <div>
                        <div>Cannot be stacked for use</div>
                        <div>Deadline {{ item.end_time }}</div>
                    </div>
                    <div v-if="item.use_status == 1 || item.use_status == 0" class="btn">{{ item.use_status == 1
                        ? 'Use' : 'Get' }}
                    </div>
                </div>
            </div>
        </div> -->
    </Slide>
</template>

<script setup>
import Slide from "@/components/Slide/index.vue"
import { ref } from "vue"
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import { getCouponList } from "@/api/home"
import CouponsItem from "@/components/couponsItem.vue"
const list = ref([])
const getList = () => {
    getCouponList().then((res) => {
        list.value = res
    })
}
const getSignUrl = (index) => {
    console.log("index", index)
    let url = "";
    if (index === 1) {
        url = require("../../../assets/images/coupon/img_couponcentre_received_3x.png")
    } else if (index == 2) {
        url = require("../../../assets/images/coupon/img_coupon_beused_3x.png")
    } else if (index == 3) {
        url = require("../../../assets/images/coupon/img_coupon_failure_3x.png")
    }
    return url
}
getList()
</script>

<style scoped lang="scss">
.coupons-list-content {
    height: 105px;
    margin-top: 12px;

    .item:last-child {
        margin-right: 0px !important;
    }

    .item {
        margin-right: 12px;
        background: url(../../../assets/images/coupon/bg_coupon_3x.png) no-repeat;
        height: 105px;
        min-width: 350px;
        background-size: contain;
        display: flex;
        position: relative;

        .left {
            width: 109px;
            color: #e41f19;

            >div:nth-child(1) {
                height: 46px;
                line-height: 46px;
                text-align: center;

                >span:nth-child(1) {
                    font-size: 15px;
                }

                >span:nth-child(2) {
                    font-size: 25px;
                    line-height: 34px;
                    font-weight: bold;
                }
            }

            >div:nth-child(2) {
                background: #F7F7F7;
                padding: 4px 5px;
                font-size: 12px;
                line-height: 13px;
                font-weight: 400;
                color: #666;
                // margin-top: 12px;
            }
        }

        .right {
            flex: 1;
            display: flex;
            // align-items: center;
            // justify-content: space-between;
            padding-left: 12px;
            box-sizing: border-box;
            flex-direction: column;
            position: relative;

            >div:nth-child(1) {
                display: flex;
                height: 52px;
                align-items: center;

                >div:nth-child(1) {
                    padding: 3px;
                    background: #FFEBEB;
                    color: #e41f19;
                    font-size: 12px;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-transform: scale(0.9);
                    transform: scale(0.9);
                    -webkit-transform-origin: 0 center;
                    transform-origin: 0 center;
                    border-radius: 2px;
                    flex-shrink: 0;
                }

                >div:nth-child(2) {
                    font-size: 14px;
                    line-height: 14px;
                    width: 100%;
                    font-size: 13px;
                    color: #333;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            >div:nth-child(2) {
                display: flex;
                padding-top: 13px;

                >div:nth-child(1) {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 400;
                    color: #666;
                    padding-left: 4px;
                    white-space: nowrap;
                    max-width: 80%;
                    margin-right: 6px;

                    >div:nth-child(2) {
                        position: relative;
                        padding-left: 8px;

                        &::before {
                            content: '';
                            display: inline-block;
                            width: 4px;
                            height: 4px;
                            background: rgb(160, 160, 160);
                            border-radius: 50%;
                            // margin-right: 4px;
                            position: absolute;
                            top: 50%;
                            left: 0px;
                            transform: translateY(-50%);
                        }
                    }
                }

                >div:nth-child(2) {
                    font-size: 16px;
                    // color: #000;
                    cursor: pointer;
                    text-align: right;
                    background-color: #e41f19;
                    width: 60px;
                    padding: 4px 0px;
                    color: #fff;
                    text-align: center;
                    border-radius: 20px;
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    line-height: 16px;

                }
            }
        }
    }

    .left-icon,
    .right-icon {
        width: 33px;
        height: 33px;
        background: #FF9933;
        border-radius: 50%;
        font-size: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tui-coupon-sign {
        height: 55px;
        width: 50px;
        position: absolute;
        z-index: 9;
        top: -15px;
        right: 20px;
    }

}
</style>