import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/home/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/productList/index.vue')
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/productDetail/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component:  () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component:  () => import(/* webpackChunkName: "about" */ '../views/pay/index.vue')
  },
  {
    path: '/user',
    name: 'user',
    component:  () => import(/* webpackChunkName: "about" */ '../views/user/index.vue')
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component:  () => import(/* webpackChunkName: "about" */ '../views/wishlist/index.vue')
  },
  {
    path: '/car',
    name: 'car',
    component:  () => import(/* webpackChunkName: "about" */ '../views/car/index.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component:  () => import(/* webpackChunkName: "about" */ '../views/orderDetail/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
