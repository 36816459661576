<template>
    <div class="layout">
        <HeaderItem :isSearch="isSearch"/>
        <div class="layout-content">
            <MenuItem v-if="isMenu" :keepLeft="keepLeft" />
            <div class="main-content">
                <slot></slot>
                <FooterItem v-if="showFooter" />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderItem from './Header.vue';
import FooterItem from './Footer.vue';
import MenuItem from './Menu.vue';
export default {
    name: 'HomeView',
    components: {
        HeaderItem,
        FooterItem,
        MenuItem
    },
    props: {
        showFooter: {
            default: false
        },
        isSearch: {
            default: false
        },
        isMenu:{
            default: true
        },
        keepLeft:{
            default: false
        },
    }
}
</script>
<style lang="scss" scoped>
.layout {
    width: 100%;
    height: 100%;
    // display: flex;
    // flex-direction: column;

    .layout-content {
        display: flex;
        width: 100%;
        // flex: 1;
        overflow: auto;
        // height: calc(100% - 74px);
        align-items: stretch;
        padding-top: 74px;
        height: calc(100% - 74px);

       .main-content {
            flex: 1;
            // width: calc(100% - 267px);
            min-width: 900px;
            height: 100%;
            overflow: auto;
        }
    }
}
</style>