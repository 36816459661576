<template>
    <div class="hot-spot-content">
        <!-- 首页内容 -->
        <div class="left">
            <p class="title">NEW</p>
            <!-- hotData -->
            <div class="df">
                <div @click="toDtail(item)" class="content" v-for="(item,index) in hotData" :key="index">
                    <div class="pic" :style="{
                        backgroundImage: `url(${item.thumbnail})`
                    }">
                    </div>
                    <p class="name text-ellipsis">{{item.title}}</p>
                    <p class="price"><span>৳</span>{{showPrice(item)}}</p>
                </div>
            </div>

        </div>
        <!-- newData -->
        <div class="right">
            <p class="title">POPULAR</p>
            <div class="df">
                <div @click="toDtail(item)" class="content" v-for="(item,index) in newData" :key="index">
                    <div class="pic" :style="{
                        backgroundImage: `url(${item.thumbnail})`
                    }"></div>
                    <p class="name text-ellipsis">{{item.title}}</p>
                    <p class="price"><span>৳</span>{{item.price}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue"
import { useStore, mapGetters } from 'vuex'
import { getMallItem } from "@/api/home.js"
import { useRouter } from "vue-router"
const router = useRouter()
const hotData = ref([])
const newData = ref([])
const initData = ()=>{
    //最新
    getMallItem({
        page: '1,4',
    }).then((res)=>{
        hotData.value = res.data || []
    })
    getMallItem({
        type:'hot',
        page: '1,4',
    }).then((res)=>{
        newData.value = res.data || []
    })
}
initData()
const $store = useStore()
const toDtail = (data) => {
    router.push(`/detail/${data.id}`)
}
// const hotData = computed(() => {
//     return $store.state.baseData.hotData.items
// })
// const newData = computed(() => {
//     return $store.state.baseData.newData.items
// })
</script>

<style scoped lang="scss">
.hot-spot-content {
    display: flex;
    height: 246px;
    margin-top: 12px;

    >div {
        flex: 1;
        padding: 20px;
        background-color: #fff;
        border-radius: 3px;
        max-width:calc(50% - 46px);
        // display: flex;

        .content {
            flex: 1;
            // width: 116px;
            height: 96px;
            margin-right: 18px;
            max-width: calc(25% - 13.5px);
            cursor: pointer;

            .pic {
                width: 116px;
                height: 96px;
                margin-bottom: 14px;
                transition: all 0.3s;
                margin: 0 auto;
                background-size: 100% 100%;
            }
            &:hover{
                .pic{
                    transform: scale(0.95);
                }
            }

            .name {
                font-weight: 400;
                font-size: 15px;
                color: #333333;
                line-height: 23px;
            }

            .price {
                font-weight: 600;;
                font-size: 15px;
                color: #CF3056;
                line-height: 22px;

                span {
                    margin-right: 5px;
                }
            }
        }

        .content:last-child {
            margin-right: 0px;
        }

    }

    .left {
        // flex: 1;
        margin-right: 12px;

        // background-color: #fff;
    }

    .right {
        // background-color: #fff;
    }

    .title {
        font-weight: 900;
        font-size: 16px;
        color: #333333;
        line-height: 24px;
        text-align: left;
        margin-bottom: 20px;
    }


}
</style>