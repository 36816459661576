<template>
    <div class="card-list-content">
        <!-- 首页内容 -->
        <div class="title"><span>{{ data.name }}</span>
            <div class="btn" v-if="hasMore" @click="toMore()" >View More</div>
        </div>
        <!-- <div class="df list" >
            <Card class="card" v-for="(item,index) in cardList" :data="item" :key="index"/>
        </div> -->
        <el-row>
            <el-col v-for="(item,index) in cardList" :data="item" :key="index" v-bind="{...grid}">
                <Card class="card"  :data="item" :key="index"/>
            </el-col>
            <!-- <el-col :xs="4" :sm="6" :md="8" :lg="9" :xl="11">
            <div class="grid-content ep-bg-purple-light" />
            </el-col>
            <el-col :xs="4" :sm="6" :md="8" :lg="9" :xl="11">
            <div class="grid-content ep-bg-purple" />
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1">
            <div class="grid-content ep-bg-purple-light" />
            </el-col> -->
        </el-row>
    </div>
</template>

<script setup>
import { getMallItem } from "@/api/home.js"
import { ref } from "vue"
import Card from "@/components/card.vue";
import { useRouter  } from "vue-router"
const grid = {
    xs:6,
    sm:6,
    md:6,
    lg:6,
    xl:4
}
const props = defineProps({
    data:{
        default:()=>({id:'',name:''})
    }
})
const cardList = ref([])
const data = props.data
const hasMore = ref(false)
const router = useRouter()
const toMore = () => {
    // category_id
    localStorage.setItem("categoryName",data.name)
    localStorage.setItem("pid",'')
    localStorage.setItem("parentMenu",'')
    router.push(`/search?id=${data.id}`)
}
const getDetail = () => {
    getMallItem({
        category_id: data.id,
        page: '1,12',
    }).then(res=>{
        cardList.value = res.data || []
        if(res.total > 12){
            hasMore.value = true
        }else{
            hasMore.value = false
        }
        console.log("res",res)
    })
}

getDetail()
console.log("data",data)
</script>

<style  lang="scss">
.card-list-content {
    background-color: #fff;
    margin-top: 12px;
    
    .title {
        font-weight: 900;
        font-size: 20px;
        color: #333333;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        padding: 26px 28px;
        border-bottom: 1px solid #eee;
        align-items: center;
        >span:nth-child(1){
            font-size: 24px;
        }
        .btn{
            width: 92px;
            height: 35px;
            background: #FF9933;
            border-radius: 3px 3px 3px 3px;
            color: #fff;
            text-align: center;
            line-height: 35px;
            font-weight: 400;
            cursor: pointer;
        }
       
    }
    .list{
        border-top:1px solid #F8F8F8;
        border-left: 1px;
        display: flex;
        flex-wrap: wrap;
        .card{
            width: 25%;
        }
     
       .card:nth-child(n){
            border-left: 0px;
        }
    }
    .rate{
        color: #909399;
        font-weight: 400;
        font-size: 14px;
        color: #909399;
        line-height: 22px;
        display: flex;
        align-items: center;
    }
    
}
</style>