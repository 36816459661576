
import { getUserData } from '@/api/base'
import { createStore } from 'vuex'
import { getcCategories } from "@/api/home.js"
export default createStore({
  state: {
    baseData:{
      user_type:'',
      menu:[[]],
      cheapBao:{
        items:[]
      },
      dayBao:{
        items:[]
      },
      category:[],
      firstLevelCategories:[] //一级类目
    },
    userInfo:{}, //用户基本信息
    currentTopMenu:''
  },
  getters: {
    menu:(state)=> state.baseData.menu,
    priceType:(state)=> state.baseData.user_type === 2 ? 'agent_price': state.baseData.user_type == 1 ? 'vip_price' : 'price'
  },
  mutations: {
    setBaseData(state,data){
      state.baseData = data
    },
    setUserData(state,data){
      state.userInfo = data
    },
    setTopMenu(state,data){
      state.currentTopMenu = data
    },
    setFirstLevelCategories(state,data){
      state.firstLevelCategories = data
    },
  },
  actions: {
    getBaseUserData(context){
      getUserData().then((res) => {
        context.commit("setUserData", res)
      })
    },
    getFirstLevelCategories(context){
      getcCategories().then((res) => {
        context.commit("setFirstLevelCategories", res)
      })
    }
  },
  modules: {
  }
})