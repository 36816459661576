<template>
    <div class="home" id="home">
        <img class="talk" src="../../assets/images/talk.svg" @click="toTalk"/>
      
        <Layout :showFooter="true">
            <div class="content">
                <Ads />
                <ShopList/>
                <CouponsList/>
                <HotSpot/>
                <CardList v-for="(item,index) in firstLevelCategories" :key="index" :data="item"/>
            </div>
        </Layout>
        <!-- 原来这里v-model 绑定的show_mask ，不知道用来干嘛的 直接删了先 TODO --easinlee-->
        <el-dialog
            v-model="is_show" 
            class="site-content"
            @close="el_close"
        >
           <img class="site-img" @click="toLink" style="max-width: 80vw;width: 100%;" :src="show_Pic"/>
        </el-dialog>
    </div>
</template>

<script setup>
import Layout from '@/components/Layout/index.vue';
import Ads from './components/Ads.vue'
import ShopList from "./components/ShopList.vue"
import CouponsList from "./components/CouponsList.vue"
import CardList from "./components/CardList.vue"
import HotSpot from "./components/HotSpot.vue"
import { useStore } from 'vuex'
import { computed,ref } from "vue"
import { getSetting } from "@/api/home.js"
import { useRouter } from 'vue-router';
const store = useStore()
const firstLevelCategories = computed(()=>{
    return store.state.firstLevelCategories
})
const toTalk  = ()=>{
    window.open('https://m.me/LisaXie01', '_blank', 'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes')
}
const is_show = ref(false)
const already_show_flag = localStorage.getItem('already_show_flag')
if (already_show_flag){
    is_show.value = false
}else{
    is_show.value = true
}
const show_mask	 = ref(false)
const show_Pic = ref('')
const siteData = ref({})
const initData = async () => {
   const res = await getSetting()
   siteData.value = res
   show_mask.value = res.show_mask === "1" && res.site_pc_mask
   show_Pic.value = res.site_pc_mask
}
const router = useRouter()
const toLink = () => {
    if(siteData.value.popup_type === 'product link'){
        show_mask.value = false
        window.open(siteData.value.product_url, "_blank", "scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes")
    }else{
        localStorage.setItem("product_url",siteData.value.product_url )
        router.push('/user?on=13&id=19')
        show_mask.value = false
    }
}
initData()
const el_close = () => {
    localStorage.setItem('already_show_flag',true)
}
// const getBase = () => {
//     getcCategories().then(res=>{
//         categoriesList.value = res
//     })
// }
// getBase()
// export default {
//     name: 'HomeView',
//     components: {
//         Layout,
//         Ads,
//         ShopList,
//         Content,
//         CouponsList,
//         CardList,
//         HotSpot
//     }
// }
</script>
<style lang="scss" scoped>
.home {
    width: 100%;
    height: 100%;
    .content{
        padding: 12px 12px 12px 0px;
        // width: calc(100% - 24px);
        overflow: hidden;
    }
    .talk{
        position: fixed;
        bottom: 48px;
        right: 36px;
        cursor: pointer;
        z-index:1;
    }
}
</style>
<style lang="scss">
.site-content{
    padding:0px !important;
    background-color:transparent !important;
    .el-dialog{
        padding: 0px !important;
    }
    .el-dialog__header{
        padding: 0px !important;
    }
}
</style>