<template>
    <div @click="emits('click',item)" class="coupons-item">
        <img :src="getSignUrl(item.use_status)" class="tui-coupon-sign" v-if="item.use_status >= 1">
        </img>
        <div class="left">
            <div>
                <span>৳</span>
                <span>{{ item.value }}</span>
            </div>
            <div>Minimum consumption ৳{{ item.limit }}</div>
        </div>
        <div class="right">
            <div>
                <div>Coupon</div>
                <div>All products are available</div>
            </div>
            <div>
                <div>
                    <div>Cannot be stacked for use</div>
                    <div>Deadline {{ item.end_time }}</div>
                </div>
                <div :loading="loading" @click="couponOption(item.use_status)" v-if="item.use_status === 1 || item.use_status === 0" :class="{
                    'btn': item.use_status === 0
                }">{{ item.use_status === 1
                    ? 'Use' : 'Get' }}
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="js" setup>
import { getCoupon } from "@/api/home.js"
import { toRef,ref } from "vue"
const props = defineProps({
    item: {
        default: () => ({})
    }
})
const item = toRef(props, "item")
const loading = ref(true)
const getSignUrl = (index) => {
    let url = "";
    if (index === 1) {
        url = require("../assets/images/coupon/img_couponcentre_received_3x.png")
    } else if (index == 2) {
        url = require("../assets/images/coupon/img_coupon_beused_3x.png")
    } else if (index == 3) {
        url = require("../assets/images/coupon/img_coupon_failure_3x.png")
    }
    return url
}

const couponOption = (status) => {
    if (status === 0) {
        loading.value = true
        getCoupon({
            coupon_id:item.value.id
        }).then(()=>{
            item.value.use_status = 1
        }).finally(()=>{
            loading.value = false
        })
    }
}
const emits = defineEmits(['click'])
</script>
<style lang="scss" scoped>
// .coupons-Item{
//     >div:nth-child(1){
//         width: 116px;
//         height: 96px;
//         border-radius: 3px 3px 3px 3px;
//         overflow: hidden;
//     }
// }
.coupons-item {
    margin-right: 12px;
    background: url(../assets/images/coupon/bg_coupon_3x.png) no-repeat;
    height: 105px;
    min-width: 350px;
    background-size: contain;
    display: flex;
    position: relative;
    overflow: hidden;

    .left {
        width: 109px;
        color: #e41f19;

        >div:nth-child(1) {
            height: 46px;
            line-height: 46px;
            text-align: center;

            >span:nth-child(1) {
                font-size: 15px;
            }

            >span:nth-child(2) {
                font-size: 25px;
                line-height: 34px;
                font-weight: bold;
            }
        }

        >div:nth-child(2) {
            background: #F7F7F7;
            padding: 4px 5px;
            font-size: 12px;
            line-height: 13px;
            font-weight: 400;
            color: #666;
            // margin-top: 12px;
        }
    }

    .right {
        flex: 1;
        display: flex;
        // align-items: center;
        // justify-content: space-between;
        padding-left: 12px;
        box-sizing: border-box;
        flex-direction: column;
        position: relative;

        >div:nth-child(1) {
            display: flex;
            height: 52px;
            align-items: center;

            >div:nth-child(1) {
                padding: 3px;
                background: #FFEBEB;
                color: #e41f19;
                font-size: 12px;
                line-height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                -webkit-transform: scale(0.9);
                transform: scale(0.9);
                -webkit-transform-origin: 0 center;
                transform-origin: 0 center;
                border-radius: 2px;
                flex-shrink: 0;
            }

            >div:nth-child(2) {
                font-size: 14px;
                line-height: 14px;
                width: 100%;
                font-size: 13px;
                color: #333;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        >div:nth-child(2) {
            display: flex;
            padding-top: 13px;

            >div:nth-child(1) {
                font-size: 12px;
                line-height: 14px;
                font-weight: 400;
                color: #666;
                padding-left: 4px;
                white-space: nowrap;
                max-width: 80%;
                margin-right: 6px;

                >div:nth-child(2) {
                    position: relative;
                    padding-left: 8px;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        background: rgb(160, 160, 160);
                        border-radius: 50%;
                        // margin-right: 4px;
                        position: absolute;
                        top: 50%;
                        left: 0px;
                        transform: translateY(-50%);
                    }
                }
            }
            >div:nth-child(2){
                font-size: 16px;
                text-align: right;
                position: absolute;
                bottom: 12px;
                right: 12px;
                line-height: 16px;
            }

            .btn {
                font-size: 16px;
                // color: #000;
                cursor: pointer;
                text-align: center !important;
                background-color: #e41f19;
                width: 60px;
                padding: 4px 0px;
                color: #fff;
                text-align: center;
                border-radius: 20px;
                position: absolute;
                bottom: 12px;
                right: 12px;
                line-height: 16px;

            }
        }
    }

    .tui-coupon-sign {
        height: 40px;
        position: absolute;
        right: 20px;
        top: -10px;
        z-index: 1;
    }
}
</style>