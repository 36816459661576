<template>
    <div class="card" @click="toDtail">
        <div class="pic">
            <img class="avatar" :src="data.thumbnail" />
        </div>
        <div class="name text-ellipsis" :title="data.title">{{ data.title }}</div>
        <div class="rate">
            <div>
                 <el-rate v-show="data.total_reviews" disabled :colors="['#FFB400', '#FFB400', '#FFB400']"
                    :model-value="data.score" /><span v-show="data.total_reviews">({{ data.total_reviews || 0 }})</span>
            </div>
            <div>
                <span>Sold：</span>
                <span>{{ data.sales }}</span>
            </div>
        </div>
        <div class="price">
            <div>
                <span>৳</span>
                <span>{{ showPrice(data) }}</span>
            </div>
            <span class="del">৳{{ data.original_price }}</span>
        </div>
    </div>
</template>
<script setup>
import { useRouter } from "vue-router"
const router = useRouter()
const props = defineProps({
    data: {
        default: () => ({ title: '', name: '', original_price: '', thumbnail: '' })
    }
})
const data = props.data
const toDtail = () => {
    router.push(`/detail/${data.object_id || data.id}`)
}
</script>
<style lang="scss" scoped>
.card {
    cursor: pointer;
    padding: 20px 28px;
    background-color: #fff;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;

    &:hover {
        .pic {
            transform: scale(0.95);
        }
    }

    .pic {
        width: 100%;
        height: 200px;
        // background-position: center center;
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        transition: all 0.3s;

        img {
            width: 100%;
            object-fit: contain;
            object-position: center center;
            height: 200px;
            min-height: 100%;
        }
    }

    .name {
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        line-height: 23px;
    }

    .rate {
        display: flex;
        align-items: center;
        min-height: 40px;
        justify-content: space-between;
        >div{
            display: flex;
            align-items: center;
        }
    }

    .price {

        // margin-top: 8px;
        >div {
            font-weight: 600;
            font-size: 15px;
            color: #CF3056;
            line-height: 22px;
        }

        >div:nth-child(2) {
            color: #606266;
            font-weight: 400;
            font-size: 14px;
            color: #606266;
            line-height: 20px;
        }

        display: flex;
        // justify-content: space-between;

        .del {
            font-weight: 400;
            font-size: 15px;
            color: #909399;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-decoration-line: line-through;
            margin-left: 12px;
        }

    }
}
</style>