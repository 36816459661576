<template>
    <header class="header-content">
        <img @click="toHome" class="logo-icon" src="../../assets/images/base/logo.svg" />
        <div class="search-input">
            <input @keydown.enter="searh" v-model.trim="searchVal" :placeholder="placeholderVal" />
            <div class="search-icon" @click="searh">
                <img src="../../assets/images/base/search.svg" />
            </div>
        </div>
        <div class="option-right">
            <img @click="toPath('car')"  v-if="isActived !== 'car'"   id="ele_car_icon" src="../../assets/images/base/cart.svg" />
            <img v-else src="../../assets/images/base/cart-actived.svg"/>
            <img  v-if="isActived !== 'user?on=1'"  @click="toPath('user?on=1')" src="../../assets/images/base/order.svg" />
            <img v-else src="../../assets/images/base/order-actived.svg"/>
            <img v-if="isActived !== 'wishlist'" @click="toPath('wishlist')" src="../../assets/images/base/like.svg" />
            <img v-else src="../../assets/images/base/like-actived.svg"/>
            <img @click="toPath('user?on=0')" v-if="isActived !== 'user?on=0'" src="../../assets/images/base/user.svg" />
            <img v-else src="../../assets/images/base/user-actived.svg"/>
        </div>
    </header>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { onBeforeUnmount } from "vue";
import { ref } from "vue"
import { useRouter,useRoute, onBeforeRouteUpdate } from "vue-router";
import { useStore } from "vuex"
const menuList = ['car','user?on=1','wishlist','user?on=0']
const store = useStore()
const router = useRouter()
const route = useRoute()
const props = defineProps({
    isSearch:{
        default:false
    }
})
onMounted(()=>{
    times = setInterval(()=>{
     placeholderTimes.value++
   },3000)
    console.log("route",route)
    if(menuList.includes(route.name)){
        store.commit('setTopMenu',route.name)
    }else if(route.name === 'user'){
        if(route.query.on === '1'){
             store.commit('setTopMenu',`${route.name}?on=${route.query.on}`)
        }else{
            store.commit('setTopMenu',`${route.name}?on=0`)
        }
       
    }else{
        console.log("111")
        store.commit('setTopMenu',``)
    }
})
const isActived = computed(()=>{
    return store.state.currentTopMenu
})

//Search by Keywords
const placeholderTimes = ref(0)
const placeholderVal = computed(()=>{
    return placeholderTimes.value % 2 ?  "Search by Product id or SKU" : "Search by Keywords" 
})

// const isActived = ref('')
const toPath = (item) => {
    if(!localStorage.getItem("token")){
        router.push('/login')
        return
    }
    store.commit('setTopMenu',item)
    router.push(`/${item}`)
}
const searchVal = ref(props.isSearch ? route.query.val :'' )
const emit = defineEmits(['search'])
const searh = () => {
    localStorage.setItem("pid",'')
    localStorage.setItem("parentMenu",'')
    localStorage.setItem("categoryName",'')
    router.push(`/search?val=${searchVal.value}`)
}
let times = null
//  onMounted(()=>{
//     times = setInterval(()=>{
//     placeholderTimes.value++
//    },3000)
//  })
 onBeforeUnmount(()=>{
    times && clearInterval(times)
 })
 onBeforeRouteUpdate(() => {
    console.log("rouet",route)
 })
 const toHome = () => {
    router.push('/')
 }
 
</script>

<style scoped lang="scss">
.header-content {
    // position: relative;
    // left: 0px;
    // top: 0px;
    width: calc(100% - 90px);
    height: 74px;
    // opacity: 1;
    background: #FF9933;
    padding: 0px 45px;
    // overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    z-index: 99;
    min-width:900px;

    .logo-icon {
        height: 78px;
        width: 78px;
        cursor: pointer;
    }

    .search-input {
        width: 598px;
        height: 45px;
        max-width: 50%;
        background: #FFFFFF;
        border-radius: 412px 412px 412px 412px;
        display: flex;
        overflow: hidden;


        input {
            border: none;
            flex: 1;
            height: 100%;
            padding: 0px 20px;

            &:focus {
                outline: none;
            }
        }

        .search-icon {
            width: 67px;
            height: 45px;
            background-color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
                width: 22.5px;
                height: 22.5px;
            }
        }
    }

    .option-right {
        img {
            width: 36px;
            height: 36px;
            cursor: pointer;
            margin-left: 17px;
        }
    }
}
</style>