<template>
  <router-view />
</template>
<script setup>
import { portalIndex } from "@/api/home.js"
import { ref, onMounted } from "vue"
import { useStore, mapState } from "vuex"
import { getUserData } from "@/api/base"
import { getCurrentInstance } from 'vue'
import { useRoute } from "vue-router"
const route = useRoute()
// console.log("route.query.parent_id",route,route.query.parent_id)
onMounted(()=>{
  setTimeout(()=>{
    if(route.query.parent_id){
      localStorage.setItem("parent_id",route.query.parent_id)
    }
  })
  // console.log("route.query.parent_id-1",route,route.query.parent_id)
})

// import { ElMessage } from 'element-plus'
// const { appContext } = getCurrentInstance();
// ElMessage({}, appContext)
const list = ref([])
const store = useStore()
const getDetail = ()=>{
  portalIndex()
    .then(res=>{
      store.commit("setBaseData",res)
      // list.value = res
    })
    getUserData().then((res)=>{
      store.commit("setUserData",res)
    }).catch(()=>{
      
    })
    store.dispatch("getFirstLevelCategories")
} 
getDetail()
</script>
<!-- <style lang="scss">
@import './assets/css/index.css';

</style> -->
