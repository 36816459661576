import { get ,post} from "./index"

export const portalIndex = () => {
    return get('portal/index')
}
export const getBanner = () => {
    return get('home/slides/1')
}
export const getNavList = (data) => {
    return get('portal/nav',data)
}
export const getCouponList  = ()=>{
    return get('coupon/couponList')
}
export const getcCategories  = (data={})=>{
    return get('mall/categories',data)
}
export const getMallItem  = (data)=>{
    return get('mall/itemv2',data)
}
export const getPortalAds  = (data)=>{
    return get('portal/ads',data)
}

// 获取优惠券
export const getCoupon  = (data)=>{
    return post('mall/Item/getCoupon',data,true,'Colleted')
}

//开屏广告
export const getSetting  = ()=>{
    return get('site/setting')
}