<template>
    <div class="slide-content" ref="slideContent">
        <div @click="toleft" class="left-icon" v-if="showLeft">
            <slot name="leftIcon" />
        </div>
        <div @click="toRight" class="right-icon" v-if="showRight">
            <slot name="rightIcon" />
        </div>
        <div class="content" :style="{
            'transform': `translateX(-${left}px)`
        }">
            <slot />
        </div>

    </div>
</template>
<script setup>
// import { id } from 'element-plus/es/locale';
import { ref, onMounted } from 'vue';
const props = defineProps(['step'])
const step = props.step
const slideContent = ref(null);
const showLeft = ref(false);
const showRight = ref(false);
let timeOut = null
onMounted(() => {
    // 创建观察对象
    const resizeObserver = new ResizeObserver(entries => {
        // console.log(entries[0]);
        timeOut && clearTimeout(timeOut)
        timeOut = setTimeout(() => {
            checkOption
        }, 200)
        // size.value = `${entries[0].contentRect.width} * ${entries[0].contentRect.height}`;
    });
    resizeObserver.observe(slideContent.value);
    checkOption()
});
const left = ref(0)
let maxW = 0
const toRight = () => {
    const nVal = left.value + step
    console.log("maxW",maxW,nVal)
    if(nVal >= maxW){
        left.value = maxW
        showRight.value = false
    }else{
        left.value = nVal
    }
    showLeft.value = true
}
const toleft = () => {
    const nVal = left.value - step
    if(nVal > 0){
        left.value = nVal
    }else{
        left.value = 0
        showLeft.value = false
    }
    showRight.value = true
}
const checkOption = () => {
    maxW = slideContent.value.scrollWidth - slideContent.value.offsetWidth
    if (slideContent.value.scrollWidth > slideContent.value.offsetWidth) {
        if (left.value + slideContent.value.offsetWidth >= slideContent.value.scrollWidth) {
            showRight.value = false
            showLeft.value = true
        } else if (left.value === 0) {
            showRight.value = true
            showLeft.value = false
        } else {
            showRight.value = true
            showLeft.value = true
        }
    } else {
        showRight.value = false
        showLeft.value = false
    }

    console.log("slideContent.value", slideContent.value.scrollWidth, slideContent.value.offsetWidth)
}
</script>
<style lang="scss" scoped>
.slide-content {
    // display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    .content{
        display: flex;
        transition: all 0.3s;
    }

    .left-icon,
    .right-icon {
        // display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transform: scaleX(0) translateY(-50%);
        transition: all 0.03s;
        z-index: 1;
    }

    .left-icon {
        left: 8px;
    }

    .right-icon {
        right: 8px;

    }

    &:hover {

        .left-icon,
        .right-icon {
            // display: block;
            transform: scaleX(1) translateY(-50%);
        }
    }
}
</style>