<template>
    <div class="ads-content">
        <div class="left">
            <el-carousel :height="height" >
                <el-carousel-item v-for="item in bannerList" :key="item.id">
                    <div class="ads-image" >
                        <img  :src="item.image"/>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="right">
            <el-carousel :height="height" >
                <el-carousel-item v-for="item in rightList" :key="item.id">
                    <div v-if="isPic(item.path)" class="ads-image">
                        <img :src="item.path"/>
                    </div>
                    <div class="ads-image" v-else>
                        <video style="width: 100%;height: 100%;" ref="video" width="100%"  controls="" :autoplay="false">
                        <source :src="item.path" type="video/mp4">
                        <p>
                            你的浏览器不支持 HTML5 视频。
                        </p>
                    </video>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script setup>
import { getBanner ,getPortalAds} from "@/api/home.js"
import { ElCarousel, ElCarouselItem } from "element-plus"
import { onMounted, onUnmounted } from "vue";
import { ref } from "vue";
const bannerList = ref([])
const rightList = ref([])
const getBase = () => {
    getBanner().then(res=>{
        bannerList.value = res[0].items
    })
    getPortalAds().then((res)=>{
        console.log("res",res)
        rightList.value = res
    })
}

getBase()
const height  =ref(`${window.document.body.clientHeight * 0.5}px`)
onMounted(()=>{
    changeSize()
    window.addEventListener('resize', changeSize);
})
onUnmounted(()=>{
    window.removeEventListener("resize",changeSize)
})
const changeSize = () =>{
     height.value  = `${window.document.body.clientHeight * 0.5}px`
}
const isPic = (name)=>{
    const imgList = ['jpg','jpeg','png','gif']
    const list = name.split('.')
    return list.length > 0 && imgList.includes(list[list.length - 1 ])
}

</script>

<style scoped lang="scss">
.ads-content {
    display: flex;
    img{
        // object-fit: cover;
        vertical-align:top;
    }

    .left {
        flex: 3;
        background-color: #fff;
        border-radius: 3px 3px 3px 3px;
        height: 100%;
    }

    .right {
        margin-left: 12px;
        flex: 1;
        background-color: #fff;
        border-radius: 3px 3px 3px 3px;
        height: 100%;
    }
    .ads-image{
        height: 100%;
        width: 100%;
        img{
            width: 100%;
            min-height: 100%;
            height: 100%;
            
        }
    }
}
</style>