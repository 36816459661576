<template>
    <nav class="nav-content">
        <ul>
            <li @click="goBack" class="parent-content" v-if="parent.length > 1">
                <img src="../../assets/images/base/left.svg" />
                <span>{{parent[parent.length - 1].name}}</span>
            </li>
            <li @click="next(item)" @mouseenter="mouseenter(item)" @mouseleave="mouseout(item)"
                v-for="(item, index) in menu" :key="index">
                <!-- {{item}} -->
                <img  v-if="parent.length <= 1 && hasImages.includes(item.name) && item.name !== 'Mother & Baby'" :src="require(`../../assets/images/base/${item.name}.jpg`)"/>
                <img  v-else-if="item.name === 'Mother & Baby'" :src="require(`../../assets/images/base/menu1.jpg`)"/>
                <div v-else-if="parent.length <= 1" style="width: 36px;height: 36px;    margin-right: 17px;"></div>
                <!-- <img :src="item.thumbnail" v-if="parent.length <= 1" /> -->
                <span>{{ item.name }}</span>
                <img v-if="item.isMove" class="actied-img" src="../../assets/images/base/left-actived.svg" />
                <img v-else src="../../assets/images/base/right.svg" />
            </li>
        </ul>
    </nav>
</template>

<script setup>
import { getcCategories } from "@/api/home.js"
import router from "@/router";
import { onBeforeUnmount } from "vue";
// import { computed } from "vue";
import { ref, toRefs, computed,watch } from "vue"
import { useRoute } from "vue-router";
import { useStore, mapGetters } from 'vuex'
const route = useRoute()
const hasImages = ['Cars & Motorcycles','Electronics','Health & Beauty','Home Supplies','Jewelry','Mother & Baby','Tools, DIY & Outdoor']
const isMove = ref(false)
const props = defineProps({
    keepLeft:{
        default:false
    }
})
const mouseenter = (item) => {
    item.isMove = true
}
const mouseout = (item) => {
    item.isMove = false
}
const parent = ref([{
    id:0
}])
const store = useStore()
const getMenuList = () => {
    getcCategories({
        pid: pid.value
    }).then((res) => {
        menu.value = res
    })
}

const pid = ref(0)
// const initData = () => {
//     getMenuList()
// }

const next = (item) => {
    // if(item.is_child !== 1){
    //     return
    // }
    if(item.is_child === 1){
        parent.value.push(item)
        pid.value = item.id
    }
    // parent.value.push(item)
    console.log("parent.value",parent.value)
    // parent.value.push(item)
    // pid.value = item.id
    localStorage.setItem('pid',pid.value)
    localStorage.setItem('parentMenu', JSON.stringify(parent.value))
    localStorage.setItem('categoryName',item.name)
    if(parent.value.length > 2 || item.is_child === 0){
        router.push(`/search?id=${item.id}`)
        localStorage.setItem("categoryName",item.name)
    }
    item.is_child === 1 && getMenuList()
}
const goBack = () =>{
    parent.value.pop()
    pid.value = parent.value[parent.value.length -1].id
    localStorage.setItem('pid',pid.value)
    localStorage.setItem('parentMenu', JSON.stringify(parent.value))
    getMenuList()
}
// onBeforeUnmount(()=>{
//     localStorage.setItem('pid',pid.value)
//     localStorage.setItem('parentMenu', JSON.stringify(parent.value))
// })
if(props.keepLeft){
    pid.value = localStorage.getItem('pid') || 0
    localStorage.getItem('parentMenu') && (parent.value = JSON.parse(localStorage.getItem('parentMenu') ))
    console.log("parent.value-000",pid.value)
    getMenuList()
}
// initData()
const firstLevelCategories = computed(()=>{
    return store.state.firstLevelCategories
})
watch(firstLevelCategories,()=>{
    console.log("gaibian ")
    menu.value = firstLevelCategories.value
})
const menu = ref( route.name !== 'search' ||  !localStorage.getItem('categoryName') ? firstLevelCategories.value : [])
console.log("menu",route.name)
// const { menu } = mapGetters(['menu'])
// const menuList = computed(()=>{
//     return  mapGetters(['menu']) ? mapGetters(['menu']).menu : []
// })
//  menu  = computed(menu.bind({$store}))
// console.log("menu",menu.value)
// 计算属性返回的 newMsg 是一个 ComputedRefImpl 引用对象
// menu = computed(menu)
</script>

<style scoped lang="scss">
.nav-content {
    min-width: 255px;
    // height: 100%;
    margin-right: 12px;
    background-color: #fff;
    height: 100%;
    overflow: auto;


    ul {
        background-color: #F2F2F2;
        li {
            height: 65px;
            display: flex;
            padding: 0px 23px;
            align-items: center;
            cursor: pointer;
            background: #fff;

            &:hover {
                span {
                    color: var(--el-color-primary);
                }

            }

            img:nth-child(1) {
                width: 36px;
                height: 36px;
                margin-right: 17px;
                position: relative;
                top: 1px;
            }

            img:nth-child(3) {
                width: 18px;
                height: 18px;
                margin-left: 6px;
            }

            span {
                display: inline-block;
                flex: 1;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 21px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #333333;
            }

            .actied-img {
                animation: leftW both 0.3s;
            }
        }
        .parent-content{
            height: 65px;
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            img{
                width: 24px !important;
                height: 24px !important;
                margin-right: 12px !important;
            }
        }
    }

    @keyframes leftW {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(4px);
        }
    }
}
</style>