<template>
    <Slide class="shop-content" :step="498">
        <template v-slot:leftIcon>
            <div class="left-icon">
                <div style="width: 18px;">
                    <ArrowLeft />
                </div>
            </div>
        </template>
        <template v-slot:rightIcon>
            <div class="right-icon">
                <div style="width: 18px;">
                    <ArrowRight />
                </div>
            </div>
        </template>
        <div class="card" v-for="(item, index) in firstLevelCategories" :key="index">
            <div>
                <router-link :to="'/search?id=' + item.id">
                    <img class="avatar" :src="item.thumbnail" v-if="item.thumbnail" />
                </router-link>                
            </div>
            <div class="name">{{ item.name }}</div>
        </div>
    </Slide>
</template>

<script setup>
import { ref,computed } from "vue";
import Slide from "@/components/Slide/index.vue"
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import { useStore } from 'vuex'

const store = useStore()
const firstLevelCategories = computed(()=>{
    return store.state.firstLevelCategories
})
// watch(firstLevelCategories,()=>{
//     console.log("gaibian ")
//     menu.value = firstLevelCategories.value
// })
</script>

<style scoped lang="scss">
.shop-content {
    margin-top: 12px;
    height: 136px;
    background-color: #fff;


    .card {
        // text-align: center;
        min-width: 166px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        height: 136px;
        >div:nth-child(1){
            width: 63px;
            height: 63px;
           img{
                // width: 63px;
            } 
        }
        
    }

    // padding-right: 12px;
    .name {
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 21px;
        margin-top: 12px;
    }

    .left-icon,
    .right-icon {
        width: 33px;
        height: 33px;
        background: #FF9933;
        border-radius: 50%;
        font-size: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
</style>