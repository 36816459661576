import axios from 'axios';
import { ElMessage ,ElMessageBox, ElNotification} from 'element-plus'
import router from '@/router';
console.log("process.env.NODE_ENV",process.env.NODE_ENV)
const http = axios.create({
    baseURL:  process.env.NODE_ENV !== 'development' ? 'https://cnbdmall.com/api':'/api', // 设置基础 URL
});
http.defaults.headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'XX-Device-Type':'web',
    'withCredentials':true
}
http.interceptors.request.use(function (config) {
    const token =  localStorage.getItem("token")
    config.headers['XX-Token'] = token
    // config.headers['XX-Device-Type'] = 'web'
    // config.headers['Content-Type'] =  'application/x-www-form-urlencoded'
    // console.log("config", config)
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
export const get = (url, params, showToast , isAll = false, checkLogin = false) => {
    return new Promise((resolve, reject) => {
        return http.get(url, {
            params: params
        }).then((res) => {
            // console.log("router.currentRoute.name",router.currentRoute,window.location.hash)
            const token =  localStorage.getItem("token")
            if ( (token || checkLogin) && window.location.hash !== '#/login' && (res.data.code == 10001 )) {
                ElMessageBox.confirm(
                    'Login has expired, please log in again',
                    'Tips',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning',
                        showCancelButton:false,
                        showClose:false,
                        distinguishCancelAndClose:false,
                        closeOnClickModal:false
                    }
                )
                    .then(() => {
                        router.push('/login')
                    })
                    .catch(() => {
                    })
                // ElMessage.success('Login has expired, please log in again')
                // uni.showModal({
                //     title: 'Tips',
                //     content: 'Login has expired, please log in again',
                //     confirmText:'confirm',
                //     cancelText:'cancel',
                //     success: (res) => {
                //         if (res.confirm) {
                //             uni.navigateTo({
                //                 url: '/pages/login/login'
                //             });
                //         }
                //     }
                // });
            } else if (res.data.code === 1 || res.data.code === 0) {
                resolve(isAll ? res.data : res.data.data)
            } else {
                console.log("res-1", res)
            }

        }).catch((error) => {
            // console.log("error",error)
            reject(error)
        })
    })
}

export const post = (url, data, showToast = false, successTips='',isAll = false) => {
    return new Promise((resolve, reject) => {
        return http.post(url, data).then((res) => {
            console.log("res2", res)
            const token =  localStorage.getItem("token")
            if ( window.location.hash !== '#/login'& (res.data.code == 10001)) {
                if(token){
                      ElMessageBox.confirm(
                        'Login has expired, please log in again',
                        'Tips',
                        {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            type: 'warning',
                            showCancelButton:false,
                            showClose:false,
                            distinguishCancelAndClose:false,
                            closeOnClickModal:false
                        }
                    )
                        .then(() => {
                            router.push('/login')
                        })
                        .catch(() => {

                        })
                }else{
                    ElMessageBox.confirm(
                        'You haven not logged in yet, please log in first',
                        'Tips',
                        {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            type: 'warning',
                            // center: true,
                            // showCancelButton:false
                        },
                        
                    )
                        .then(() => {
                            router.push('/login')
                        })
                        .catch(() => {
                        })
                }
              
            }
            else if (res.data.code === 1) {
                resolve(isAll ? res : res.data.data)
                showToast &&  ElNotification.success({
                    title: 'Info',
                    message: successTips || res.data.msg,
                    duration:2000,
                    offset: window.screen.height / 2
                  })
                // showToast && ElMessage.success(successTips || res.data.msg)
            } else if(res.data.code === 0) {
                reject( isAll ? res : new Error(res.data.msg))
                showToast && ElNotification.error({
                    title: 'Tips',
                    message: res.data.msg,
                    duration:2000,
                    offset: window.screen.height / 2
                 })
                // ElMessage({
                //     message: res.data.msg,
                //     type: 'error',
                //   })
            }else{
                reject(res.data)
            }
            // console.log("res",res)

        }).catch((error) => {
            console.log("error", error)
            reject(error)
        })
    })
}

export const deleteAxios =  (url, params, showToast , isAll = false) => {
    return new Promise((resolve, reject) => {
        return http.delete(url, {
            params: params
        }).then((res) => {
            // console.log("router.currentRoute.name",router.currentRoute,window.location.hash)
            const token =  localStorage.getItem("token")
            if (token &&  window.location.hash !== '#/login' && (res.data.code == 10001)) {
                ElMessageBox.confirm(
                    'Login has expired, please log in again',
                    'Tips',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning',
                    }
                )
                    .then(() => {
                        router.push('/login')
                    })
                    .catch(() => {
                    })
            } else if (res.data.code === 1 || res.data.code === 0) {
                resolve(isAll ? res.data : res.data.data)
            } else {
                console.log("res-1", res)
            }

        }).catch((error) => {
            // console.log("error",error)
            reject(error)
        })
    })
}
export const put = (url, data, showToast = false) => {
    return new Promise((resolve, reject) => {
        return http.put(url, data).then((res) => {
            console.log("res2", res)
            const token =  localStorage.getItem("token")
            if ( window.location.hash !== '#/login' && (res.data.code == 10001)) {
                if(token){
                      ElMessageBox.confirm(
                        'Login has expired, please log in again',
                        'Tips',
                        {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            type: 'warning',
                        }
                    )
                        .then(() => {
                            router.push('/login')
                        })
                        .catch(() => {

                        })
                }else{
                    ElMessageBox.confirm(
                        'You haven not logged in yet, please log in first',
                        'Tips',
                        {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            type: 'warning',
                            // center: true,
                            // showCancelButton:false
                        },
                        
                    )
                        .then(() => {
                            router.push('/login')
                        })
                        .catch(() => {
                        })
                }
              
            }
            else if (res.data.code === 1) {
                resolve(res.data.data)
                showToast && ElNotification.success({
                    title: 'Info',
                    duration:2000,
                    message: res.data.msg,
                    offset: window.screen.height / 2
                  })
                // showToast && ElMessage.success(res.data.msg)
            } else if(res.data.code === 0) {
                reject(new Error(res.data.msg))
                showToast && ElNotification.error({
                    title: 'Tips',
                    duration:2000,
                    message: res.data.msg,
                    offset: window.screen.height / 2
                 })
                // ElMessage({
                //     message: res.data.msg,
                //     type: 'error',
                //   })
            }else{
                reject(res.data)
            }
            // console.log("res",res)

        }).catch((error) => {
            console.log("error", error)
            reject(error)
        })
    })
}
export default http